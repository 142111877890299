"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventListeners = void 0;
var object_service_1 = require("../../object-service");
var form_const_1 = require("./form.const");
var price_preview_1 = require("./price-preview");
var EventListeners = /** @class */ (function () {
    function EventListeners() {
        this.magiclinePaymentChoice = 'DIRECT_DEBIT';
        this.priceOverview = object_service_1.ObjectService.getInstance(price_preview_1.PricePreview);
    }
    // private helper = ObjectService.getInstance(Helper);
    EventListeners.prototype.addEvents = function () {
        var _this = this;
        $('input[name="membershipId"]').on('click', function (event) {
            var inputElement = event.target;
            if (inputElement === null || inputElement === void 0 ? void 0 : inputElement.value) {
                localStorage.setItem(form_const_1.MAPPINGS.membershipId.localStorageKey, inputElement.value);
                localStorage.setItem('contract.rateBundleTermId', inputElement.value);
                _this.priceOverview.initModule();
            }
        });
        $(form_const_1.MAPPINGS.zipCode.id).on('input', function (event) {
            event.target.value = event.target.value
                .replace(/[^0-9]/g, '')
                .substring(0, 5);
        });
        $(form_const_1.MAPPINGS.telephone_mobile.id).on('input', function (event) {
            event.target.value = event.target.value.replace(/[^0-9]/g, '');
        });
        //Prepare input fields with e.g. event handlers. They will only be applied if the input exists.
        if ($(form_const_1.MAPPINGS.iban.id).length &&
            typeof $(form_const_1.MAPPINGS.iban.id).inputmask === 'function') {
            $(form_const_1.MAPPINGS.iban.id).inputmask({
                mask: 'AA99 9999 9999 9999 9999 99',
                clearMaskOnLostFocus: false,
            });
        }
        if ($(form_const_1.MAPPINGS.location.id).length) {
            $(form_const_1.MAPPINGS.location.id).on('change', function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.priceOverview.initModule();
                    return [2 /*return*/];
                });
            }); });
        }
    };
    return EventListeners;
}());
exports.EventListeners = EventListeners;
