"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MagiclineApi = void 0;
var error_handling_1 = require("../error-handling");
var object_service_1 = require("../object-service");
var variables_const_1 = require("../variables.const");
var MagiclineApi = /** @class */ (function () {
    function MagiclineApi() {
        this.errorHandling = object_service_1.ObjectService.getInstance(error_handling_1.ErrorHandling);
    }
    MagiclineApi.prototype.getStudios = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch(variables_const_1.magicLineUrl + '/connect/v2/studio', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(function (createResponse) {
                        if (!createResponse.ok) {
                            return Promise.reject(createResponse.json());
                        }
                        return createResponse.json();
                    })
                        .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
                        var error;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, createdErr];
                                case 1:
                                    error = _a.sent();
                                    if (error.message) {
                                        this.errorHandling.showErrorMessage(error.message);
                                    }
                                    throw error;
                            }
                        });
                    }); })];
            });
        });
    };
    MagiclineApi.prototype.createNewCustomer = function (body) {
        var _this = this;
        // Create a new customer and a new contract
        return fetch(variables_const_1.magicLineUrl + '/connect/v1/rate-bundle', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(function (createResponse) {
            if (!createResponse.ok) {
                return Promise.reject(createResponse.json());
            }
            return createResponse.json();
        })
            .then(function (created) {
            // Created response
            if (variables_const_1.environment === 'dev') {
                console.log('created', created);
            }
            localStorage.setItem('birthday', body.customer.dateOfBirth);
            localStorage.setItem('customer.dateOfBirth', body.customer.dateOfBirth);
            // Write response in localstorage for later usage
            var dataArray = created;
            for (var key in dataArray) {
                if (dataArray[key]) {
                    localStorage.setItem(key, dataArray[key]);
                }
            }
            return created;
        })
            .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
            var error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, createdErr];
                    case 1:
                        error = _a.sent();
                        if (error.message) {
                            this.errorHandling.showErrorMessage(error.message);
                        }
                        throw error;
                }
            });
        }); });
    };
    MagiclineApi.prototype.createLead = function (body) {
        var _this = this;
        return fetch(variables_const_1.magicLineUrl + '/connect/v1/lead', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(function (createResponse) {
            if (!createResponse.ok) {
                return Promise.reject(createResponse.json());
            }
            return createResponse.json();
        })
            .then(function (created) {
            return created;
        })
            .catch(function (createdErr) { return __awaiter(_this, void 0, void 0, function () {
            var error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, createdErr];
                    case 1:
                        error = _a.sent();
                        if (error.message) {
                            this.errorHandling.showErrorMessage(error.message);
                        }
                        throw error;
                }
            });
        }); });
    };
    // This can be used e.g. to validate a voucher
    MagiclineApi.prototype.previewNewCustomer = function (body) {
        return fetch(variables_const_1.magicLineUrl + '/connect/v1/preview', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
    };
    MagiclineApi.prototype.getOfferings = function (studioId) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var res, body, error, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, fetch(variables_const_1.magicLineUrl + '/connect/v1/rate-bundle?studioId=' + studioId, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })];
                    case 1:
                        res = _c.sent();
                        if (!res.ok) return [3 /*break*/, 3];
                        return [4 /*yield*/, res.json()];
                    case 2:
                        body = (_c.sent());
                        if (body) {
                            // if (_settings?.stripe?.stripeKey) {
                            //   const url = `https://europe-west3-named-catcher-373207.cloudfunctions.net/stripe-public-endpoint/products?accountId=${_settings.stripe.accountId}&studioId=${studioId}`;
                            //   const res: { termId: string; priceIds: string[] }[] = await (
                            //     await fetch(url, {
                            //       method: 'GET',
                            //       headers: {
                            //         'Content-Type': 'application/json',
                            //       },
                            //     })
                            //   ).json();
                            //   if (res.length === 0) {
                            //     console.error(
                            //       'No Stripe products found please check that the given contract names ',
                            //       ...body.map((a) => a.name),
                            //       ' are set in stripe!'
                            //     );
                            //   } else {
                            //     for (const contract of body) {
                            //       for (const term of contract.terms) {
                            //         for (const stripeProduct of res) {
                            //           if (String(term.id) === stripeProduct.termId) {
                            //             term.stripePriceIds = stripeProduct.priceIds;
                            //           }
                            //           // const product = body.find(
                            //           //   (a) => a.name === stripeProduct.name
                            //           // );
                            //           // if (product) {
                            //           //   product.stripePriceId = stripeProduct.default_price;
                            //           // } else {
                            //           //   if (environment === 'dev') {
                            //           //     console.error(
                            //           //       'Couldnt map stripe product',
                            //           //       res.map((a) => a.name),
                            //           //       stripeProduct,
                            //           //       ' with magicline products ',
                            //           //       body.map((a) => a.name),
                            //           //       body
                            //           //     );
                            //           //   }
                            //           // }
                            //         }
                            //       }
                            //     }
                            //     console.log('new mapping', body);
                            //   }
                            // }
                            return [2 /*return*/, body];
                        }
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, res.json()];
                    case 4:
                        error = _c.sent();
                        throw error;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_1 = _c.sent();
                        if (((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.message) === null || _a === void 0 ? void 0 : _a.length) || ((_b = error_1 === null || error_1 === void 0 ? void 0 : error_1.errorMessage) === null || _b === void 0 ? void 0 : _b.length)) {
                            return [2 /*return*/, undefined];
                        }
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    MagiclineApi.prototype.validateVoucher = function (body) {
        return __awaiter(this, void 0, void 0, function () {
            var res, error, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        return [4 /*yield*/, this.previewNewCustomer(body)];
                    case 1:
                        res = _a.sent();
                        if (!!res.ok) return [3 /*break*/, 3];
                        return [4 /*yield*/, res.json()];
                    case 2:
                        error = _a.sent();
                        throw error;
                    case 3: return [4 /*yield*/, res.json()];
                    case 4: return [2 /*return*/, _a.sent()];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        error_2 = _a.sent();
                        throw error_2;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    return MagiclineApi;
}());
exports.MagiclineApi = MagiclineApi;
